<template>
  <section class="helper relative mx-auto flex w-full flex-col px-6 py-12 lg:py-24 2xl:max-w-8xl">
    <div
      class="detail pointer-events-none absolute right-0 top-[-22px] z-10 h-[207px] w-[40vw] lg:right-[-175px] lg:top-[-43px] lg:h-[414px] lg:w-[575px] 2xl:right-[-375px] 2xl:w-[675px]"
    >
      <div></div>
    </div>
    <hgroup class="relative z-10 max-lg:mt-8">
      <h2 class="text-6xl font-bold leading-none max-lg:mb-8 lg:text-8xl" v-html="helper.title" />
      <h3 class="text-2xl" v-html="helper.subtitle" />
    </hgroup>
    <Button v-if="helper.cta.show" :href="helper.cta.url" :target="helper.cta.target" class="z-10 mr-auto mt-12">
      <i v-if="helper.cta.icon" class="mr-2 !text-2xl" :class="helper.cta.icon" />
      {{ helper.cta.label }}
    </Button>
  </section>
</template>

<script setup>
import { computed } from "vue";

import Button from "@/components/Buttons/Button/v2/Button.vue";

const props = defineProps({
  helper: {
    type: Object,
    default: () => ({}),
  },
});
const helper = computed(() => props.helper);
</script>

<style lang="scss" scoped>
@import "./../../../styles/themes/tailwind/tailwind-light/variables";

.detail {
  @media screen and (max-width: 1024px) {
    overflow: hidden;
  }
  > div,
  &:before,
  &:after {
    content: "";
    display: block;
    background-color: $primaryColor;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    position: absolute;
    width: 100%;
    height: 62px;
    @media screen and (min-width: 1024px) {
      height: 125px;
    }
  }
  > div {
    top: 0;
    left: 85px;
  }
  &:before {
    top: 72px;
    @media screen and (min-width: 1024px) {
      top: 145px;
    }
  }
  &:after {
    bottom: 0;
    left: 85px;
  }
}
</style>
